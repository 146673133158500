<template>
  <div class="uk-container uk-container-large text-white uk-padding-small uk-visible@m">
    <div class="uk-flex uk-flex-middle uk-flex-between">
      <div class="uk-flex uk-flex-middle">
	      <div class="uk-position-relative ">
        <a href="/" class="uk-navbar-item uk-logo uk-position-relative "
          ><img
            class="text-teal-14"
            src="img/sokol.svg"
            alt=""
            style="width: 100px; height: 100px;"
            uk-svg
        />
	        <div style="bottom: -5px" class="uk-position-bottom-center text-teal-14">СОКОЛ</div>

        </a>
	      </div>
        <div style="width: 230px">
          Производим и устанавливаем мебель в
	        <span class="text-teal-14">Комсомольске-на-Амуре</span> и крае с <span class="text-teal-14">2001г</span>.
        </div>
      </div>

      <nav-rating/>

      <div  class="uk-flex uk-flex-middle ">
        <div @click="sm = !sm" class="uk-flex uk-flex-middle cursor-pointer">
          <a  class="uk-navbar-item uk-logo"
            ><img
              style="
                height: 100px;
                width: 100px;
                border: 1px solid #00bfa5;
                border-radius: 50%;
                object-position: top;
                object-fit: cover;
              "
              src="img/team/designer.png"
              alt=""
          />

          </a>
          <div style="width: 200px">
            <span style="font-size: 0.9em">Есть вопрос?</span><br />
            <b
              ><span>Задайте его нашему</span><br />
              менеджеру <span class="text-teal-14">онлайн</span></b
            >
          </div>
        </div>

        <div class="uk-flex uk-flex-middle">
          <!--          <a href="/" class="uk-navbar-item uk-logo"-->
          <!--            ><img-->
          <!--              style="height: 80px; width: 80px"-->
          <!--              src="img/team/designer.png"-->
          <!--              alt=""-->
          <!--          /></a>-->

          <div class="uk-flex uk-flex-middle uk-flex-column">
            <span style="font-size: 0.9em"
              >Звоните, ежедневно 10:00 - 18:00</span
            >
            <a
              @click="clickPhone"
              href="tel:+79622972289"
              style="font-size: 2em"
              class="text-teal-14 uk-padding-remove"
              >8 (962)-297-2289</a>
	          <div class="q-gutter-sm q-mt-sm">
		          <a href="https://api.whatsapp.com/send?phone=79622972289" target="_blank"
		             rel="noopener noreferrer"><q-icon color="green" size="2em" name="whatsapp" /></a>
		          <a href="https://t.me/sokolmeb" target="_blank"
		             rel="noopener noreferrer"><q-icon color="primary" size="2em" name="telegram" /></a>

<!--		          <q-btn flat-->
<!--		                 color="green"-->
<!--		                 icon="whatsapp"-->
<!--		                 href="https://api.whatsapp.com/send?phone=79622972289"-->
<!--		                 target="_blank"-->
<!--		                 rel="noopener noreferrer"/>-->
<!--		          <q-btn flat color="primary" icon="telegram" to="https://t.me/sokolmeb" target="_blank" rel="noopener noreferrer"/>-->

	          </div>




          </div>
        </div>
      </div>
    </div>

	  <modal-zamer
			  v-model="sm"
			  :state="sm"
			  btn-title="Записаться на замер"
			  content=""
	  />

    <!--    <div class="uk-child-width-1-2" style="border: 1px red solid" uk-grid>-->
    <!--      <div>-->
    <!--        <a href="/" class="uk-navbar-item uk-logo"-->
    <!--          ><img style="height: 80px" src="img/logomin.png" alt=""-->
    <!--        /></a>-->
    <!--        <div style="width: 200px">-->
    <!--          Производим и устанавливаем мебель в-->
    <!--          <span class="text-teal-14">Краснодаре</span> и области с 2013г.-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <div class="uk-child-width-1-2" uk-grid>-->
    <!--          <div class="uk-flex uk-flex-middle">-->
    <!--            <div class="uk-flex-column">-->
    <!--              >-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div class="uk-flex"></div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <nav class="uk-navbar-container uk-light uk-visible@m" uk-navbar style="height: 40px">
    <div class="uk-navbar-center">
      <ul class="uk-navbar-nav">
        <li class="">
          <a @click="scroll('#photo','125')" class="uk-navbar-item cursor-pointer">Портфолио</a>
        </li>
        <li class="">
          <a @click="scroll('#calc','130')" class="uk-navbar-item cursor-pointer">Калькулятор стоимости</a>
        </li>
        <li class="">
          <a @click="scroll('#timeline','125')" class="uk-navbar-item cursor-pointer">Этапы работы</a>
        </li>
      </ul>
    </div>

    <!--    <div class="uk-navbar-left">-->
    <!--      <ul class="uk-navbar-nav">-->
    <!--        <li class="uk-active">-->
    <!--          <a href="/" class="uk-navbar-item uk-logo"-->
    <!--            ><img style="height: 40px" src="img/logomin.png" alt=""-->
    <!--          /></a>-->
    <!--        </li>-->

    <!--        <li-->
    <!--          class="text-white"-->
    <!--          style="align-items: center; display: flex; justify-content: center"-->
    <!--          v-if="$q.platform.is.desktop"-->
    <!--        >-->
    <!--          Качество, которому доверяют-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
    <!--    <div class="uk-navbar-right">-->
    <!--      <ul class="uk-navbar-nav">-->
    <!--        <li>-->
    <!--          <a-->
    <!--            @click="clickPhone"-->
    <!--            href="tel:+79996600369"-->
    <!--            class="uk-navbar-item text-cyan-13"-->
    <!--            >8 (999)-660-0369</a-->
    <!--          >-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
  </nav>
</template>

<script>
import ModalZamer from "../../UiComponents/ModalZamer";
import {ref} from "vue";
import {useQuasar} from "quasar";
import NavRating from "@/components/chunks/kitchen/NavRating.vue";
export default {
  name: "NavBar",
	components: {NavRating, ModalZamer},
	setup() {
		const $q=useQuasar()
		const scroll = (id, offset) => {
			if ($q.platform.is.mobile) {
				offset=offset-40
			}

			UIkit.scroll('',{offset: offset}).scrollTo(id)
		}

		const sm = ref(false);
    const clickPhone = () => {
	    ym(93834777,'reachGoal','conversionPhone')
      ym(93834777, "reachGoal", "allConvertion");
    };



    return {
      clickPhone, sm,scroll
    };
  },
};
</script>

<style scoped>
nav {
  background: #222222 !important;
}
.white {
  background: red;
  color: white;
}
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 0.25em;
  column-gap: 0.25em;
  box-sizing: border-box;
  min-height: 40px;
  padding: 0 15px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-decoration: none;
}


.blink::after {
	content: '';
	width: 10px;
	height: 10px;
	border-radius: 100%;
	border: 6px solid #00bfa5;
	position: absolute;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: ring 1.5s infinite;
}
.blink:hover::after, .blink:focus::after {
	/*animation: none;*/
	/*display: none;*/
}
@keyframes ring {
	0% {
		width: 101px;
		height: 101px;
		opacity: 1;
	}
	100% {
		width: 130px;
		height: 130px;
		opacity: 0;
	}
}
.uk-container{
  padding-top: 0;
}
</style>

<template>

  <header>
    <div
        class="uk-position-relative uk-visible-toggle uk-light"
        tabindex="-1"
        uk-slideshow="animation: fade; autoplay: true; pause-on-hover: false; autoplay-interval: 5000;"
    >

      <ul class="uk-slideshow-items">
        <li v-for="index in 6" :key="index">
          <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
            <img :src="'/img/shkaf/header/'+index+'.jpg.webp'" alt="Шкафы на заказ в Краснодаре" uk-cover>
            <teleport to="head">
              <link rel="preload" as="image" type="image/webp" :href="'/img/kitchen/header/'+index+'.jpg.webp'">
            </teleport>
          </div>
          <div class="uk-position-center text-center" v-if="$q.platform.is.desktop">
            <h1 class="q-mb-none text-h2 q-mt-none title-3d text-lime-12">Шкафы на заказ <span class="text-white">в Краснодаре</span></h1>
            <h2 class="q-mt-sm text-h3 title-3d">со скидкой <span class="text-lime-12"><b>{{ this.$store.state.StDiscountCount }}%</b></span> до конца {{ this.$store.state.StDiscountMonth }}</h2>
            <q-btn-group>
              <q-btn color="primary" type="a" href="#photo" uk-scroll="offset:130" label="смотреть фото и цены"/>
              <q-btn color="lime-12 text-black" label="Записаться на замер" @click="sm=!sm"/>
            </q-btn-group>
          </div>
          <div class="uk-position-center" v-if="$q.platform.is.mobile">
            <h1 class="text-h4 q-mb-none q-mt-none title-3d text-lime-12 caption">Шкафы на заказ <br><span class="text-h5 q-mt-none text-white">в Краснодаре</span></h1>
          </div>
          <div class="absolute-bottom q-pb-sm" v-if="$q.platform.is.mobile">
            <div class="text-center" ><q-btn class="text-black" color="lime-12" label="Вызвать дизайнера" @click="sm=!sm"/></div>
          </div>

          <div class="uk-position-top-right uk-position-small" v-if="$q.platform.is.desktop">

            <div class=" block-button">
              <a @click="clickPhone" class="uk-link " href="tel:+79693050055">

                <span class="text-h4 text-lime-12 title-3d">8 (969)-305-0055<i class="fas fa-phone padding-dot-phone q-ml-sm text-green"></i></span>


              </a>
            </div>
          </div>

          <div class="uk-position-top-center uk-position-medium" v-if="$q.platform.is.desktop">
            <a href="/" class="uk-navbar-item uk-logo blink">
              <img src="img/logo-shkaf.svg" style="width: 120px; height: 120px;"
                   alt="Кухни на заказ в DVM | 89693050055" srcset="">
            </a>
          </div>
        </li>
      </ul>

    </div>

<modal-zamer :state="sm"/>

  </header>
</template>

<script>
import {ref} from 'vue'
import ModalZamer from "../UiComponents/ModalZamer";
export default {
  name: "shkafDvmHeader",
  components: {ModalZamer},
  setup () {
    const sm = ref(false)
    const clickPhone = () => {
      ym(93834777,'reachGoal','conversionPhone')
    }
    return {
      sm,
      clickPhone
    }
  }
}
</script>

<style scoped>

.title-3d {
  text-shadow: 0px 0px 0 rgb(-64, -126, 0), 1px 1px 0 rgb(-127, -189, 0), 2px 2px 0 rgb(-191, -253, 0), 3px 3px 0 rgb(-255, -317, 0), 4px 4px 0 rgb(-319, -381, 0), 5px 5px 4px rgba(0, 0, 0, 1), 5px 5px 1px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(0, 0, 0, .2);
}


.caption{
  text-align: center;

  padding: 12px;
  color: white;
  background-color: rgba(0, 0, 0, .3);
}

.blink::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 6px solid #ffeb3b;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.blink:hover::after, .blink:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 120px;
    height: 120px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

a {
  text-decoration: none;
}

.block-button:hover {
  animation: rotate-header 1s infinite;
}
@keyframes rotate-header {
  0% {
    transform: rotate(0.01turn);
  }
  50% {
    transform: rotate(-0.01turn);
  }
  100% {
    transform: rotate(0.01turn);
  }
}
</style>
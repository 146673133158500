<template>
  <div>
    Info: {{info}}
<shkaf-dvm-header/>

<div class="uk-height-viewport"></div>
    <div class="uk-height-viewport uk-section-secondary"></div>

    <div class="cupboard">
      <div class="uk-position-top-center uk-text-center">
        <h1 class="title-3d text-lime-12 uk-margin-small-bottom">Шкаф <span class="text-white"> - это удобно</span></h1>
        <h3 class="title-3d text-lime-12 uk-margin-remove-top">Шкаф <span class="text-white"> - это хорошо</span></h3>
      </div>

    </div>


  <nav-bar/>

  </div>
</template>

<script>
import ShkafDvmHeader from "../components/Shkaf/shkafDvmHeader";
import NavBar from "../components/chunks/kitchen/NavBar";
import axios from "axios";
import {onMounted, ref} from "vue";
export default {
  name: "shkaf-na-zakaz",
  components: {NavBar, ShkafDvmHeader},
  setup() {
    const info = ref()



    onMounted(()=>{
      axios
          .get("https://dv-m.ru/mail.php")
          .then(response => (info.value = response.data))
    })
    return{
      info,
    }
  }

}
</script>

<style scoped>
.cupboard {
  background: url(/img/test/cupboard-bg1.jpg)no-repeat center top;
  min-height: 1254px;
  position: relative;
}
.title-3d {
  text-shadow: 0px 0px 0 rgb(-64, -126, 0), 1px 1px 0 rgb(-127, -189, 0), 2px 2px 0 rgb(-191, -253, 0), 3px 3px 0 rgb(-255, -317, 0), 4px 4px 0 rgb(-319, -381, 0), 5px 5px 4px rgba(0, 0, 0, 1), 5px 5px 1px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(0, 0, 0, .2);
}
</style>